import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { faCheck, faCube, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Table } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const HSRGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_introgame.png"
            alt="Introduction to the game"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>All you need to know about Honkai: Star Rail.</h2>
          <p>
            Last updated: <strong>24/01/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Introduction to the game" />
        <p>
          <strong>Honkai: Star Rail is a turn-based RPG</strong> very similar to
          old-school JRPGs (especially the Final Fantasy series). The game is
          split into two modes: overworld and battle. In the overworld, you run
          around the map and when you encounter an enemy group, you will
          'transfer' into the battle mode where you have to defeat the enemies
          in turn-based combat.
        </p>
        <p>
          We will talk about the overworld (also known as exploration) and
          combat modes in a separate section further down the guide.
        </p>
        <SectionHeader title="Characters" />
        <p>
          Characters are the playable units in Honkai: Star Rail. They are
          obtained mainly via Warps, the game’s gacha system. However, some
          Characters can be obtained for free via in-game missions or events.
        </p>
        <h5>Character Stats</h5>
        <ul>
          <li>
            <strong>HP</strong> - How much damage a Character can take before
            falling in combat
          </li>
          <li>
            <strong>ATK</strong> - How much damage a Character deals
          </li>
          <li>
            <strong>DEF </strong>- Reduces the damage a Character takes
          </li>
          <li>
            <strong>Speed</strong> - Determines how fast and how often a
            Character will act in combat
          </li>
          <li>
            <strong>Crit Rate</strong> - How likely it is for the Character to
            land a critical hit
          </li>
          <li>
            <strong>Crit DMG</strong> - Determines the damage multiplier when
            landing a critical hit
          </li>
          <li>
            <strong>Break Effect</strong> - Enhances Weakness Break effects (the
            amount of damage dealt upon it), the amount of DMG dealt by DoT's
            per turn and also how far enemy actions are delayed
          </li>
          <li>
            <strong>Outgoing Healing Boost</strong> - Determines the amount of
            healing done by the Character’s Healing ability
          </li>
          <li>
            <strong>Energy Regeneration Rate</strong> - Determines how fast a
            Character charges energy for their Ultimate ability
          </li>
          <li>
            <strong>Effect Hit Rate</strong> - Determines how likely it is for a
            Character to apply a debuff on the enemy
          </li>
          <li>
            <strong>Effect RES</strong> - Determines how difficult it is for an
            enemy to apply a debuff on the Character
          </li>
          <li>
            <strong>Elemental Damage Boost</strong> - Increases all damage a
            character deals. Note that there are seven different elements in the
            game and each element has its own separate Elemental Damage Boost
            stat
          </li>
        </ul>
        <h5>Character Elements & Paths</h5>
        <p>
          Each Character in Honkai: Star Rail has an <strong>Element</strong>{' '}
          associated with them. A Character’s Element determines the damage type
          of their attacks.
        </p>
        <p>There are currently 7 Elements in the game:</p>
        <ul>
          <li>
            <span className="hsr-dmg phys">Physical</span>
          </li>
          <li>
            <span className="hsr-dmg fire">Fire</span>
          </li>
          <li>
            <span className="hsr-dmg ice">Ice</span>
          </li>
          <li>
            <span className="hsr-dmg lightning">Lightning</span>
          </li>
          <li>
            <span className="hsr-dmg wind">Wind</span>
          </li>
          <li>
            <span className="hsr-dmg quantum">Quantum</span>
          </li>
          <li>
            <span className="hsr-dmg imaginary">Imaginary</span>
          </li>
        </ul>
        <p>
          Each Character in Honkai: Star Rail also has a <strong>Path</strong>{' '}
          as well. You can think of Paths as character classes. A Character’s
          Path will give you a general idea of what role that Character fulfills
          in combat as well as what Light Cones they can fully utilize.
        </p>
        <p>There are currently 7 Paths in the game:</p>
        <ul>
          <li>
            <strong>Destruction</strong> (Focus on Blast and Single target
            damage)
          </li>
          <li>
            <strong>Hunt</strong> (Focus on Single target damage)
          </li>
          <li>
            <strong>Erudition</strong> (Focus on AoE damage)
          </li>
          <li>
            <strong>Harmony</strong> (Focus on supporting allies, allowing them
            to do more damage)
          </li>
          <li>
            <strong>Nihility</strong> (Focus on debuffs and Damage over Time)
          </li>
          <li>
            <strong>Preservation</strong> (Focus on keeping the team alive)
          </li>
          <li>
            <strong>Abundance</strong> (Focus on keeping the team alive)
          </li>
        </ul>
        <h5>Character Skills</h5>
        <p>
          Each character in Honkai: Star Rail has 5 different abilities that
          define their playstyle.
        </p>
        <h6>
          <FontAwesomeIcon width="18" icon={faCube} />
          &nbsp; Basic Attack
        </h6>
        <p>
          The Basic Attack ability is a Character’s auto-attack and is always
          available to use during combat. While generally the ability is
          considered a filler one that is used to generate Skill Points, there
          are some characters that do majority of their damage via their Basic
          Attack,
        </p>
        <h6>
          <FontAwesomeIcon width="18" icon={faCube} />
          &nbsp; Skill
        </h6>
        <p>
          Skill is a combat ability that a Character can use with effects
          ranging from dealing damage to providing buffs for your allies. The
          Skill ability has no cooldown and instead requires you to expend Skill
          Point(s) in order to use it.
        </p>
        <h6>
          <FontAwesomeIcon width="18" icon={faCube} />
          &nbsp; Ultimate
        </h6>
        <p>
          A Character’s Ultimate ability is generally their strongest combat
          ability. To use the Ultimate ability, it must first be charged through
          certain combat actions such as attacking or defeating an enemy. It
          must also be recharged after every use.
        </p>
        <h6>
          <FontAwesomeIcon width="18" icon={faCube} />
          &nbsp; Talent
        </h6>
        <p>
          Talent is the Character’s passive ability. Generally, it takes the
          form of a conditional effect where the Talent will provide some kind
          of benefit to the Character and/or their allies when certain
          conditions are met.
        </p>
        <h6>
          <FontAwesomeIcon width="18" icon={faCube} />
          &nbsp; Technique
        </h6>
        <p>
          Unlike the rest of a Character’s abilities, Technique is an overworld
          ability rather than a combat ability and is used before combat begins.
          Depending on the Character, using it will either initiate combat with
          a special attack or it will provide a buff prior to engaging the
          enemy.
        </p>
        <SectionHeader title="Character Progression" />
        <StaticImage
          src="../../../images/starrail/generic/guide_character.jpg"
          alt="Characters"
        />
        <p>
          Upgrading your Characters in Honkai: Star Rail will enhance their
          combat capabilities, allowing you to challenge and overcome the more
          difficult content that the game offers.
        </p>
        <p>
          The main ways for a Character to grow stronger in Honkai: Star Rail
          are:
        </p>
        <ul>
          <li>Increasing the Character level</li>
          <li>Upgrading the Character’s Traces</li>
          <li>Unlocking the Character’s Eidolons</li>
          <li>Equipping Light Cones </li>
          <li>Equipping Relics and Planetary Ornaments</li>
        </ul>
        <h5>Character Level and Ascension</h5>
        <p>
          Each Character in Honkai: Star Rail has a Character Level{' '}
          <strong>ranging from 1 to 80</strong> and gains additional stats as
          they level up.
        </p>
        <p>
          Once a Character reaches certain level <strong>milestones</strong>,
          they will need to be ascended in order to increase their level
          further. Characters start with a base max Level of 20. Each time you
          ascend a Character, their level cap will increase by 10 up to a
          maximum of 80. To ascend a Character, you will need to gather
          materials from the Stagnant Shadow Bosses and enemies in the
          overworld.
        </p>
        <p>
          Also, note that your Trailblaze Level (Account Level) will also
          determine how many times you can ascend a Character. Even if you have
          all of the required ascension materials, you will not be able to
          ascend a Character past a certain Level unless your account is also of
          the appropriate Trailblaze Level.
        </p>
        <h5>Traces</h5>
        <StaticImage
          src="../../../images/starrail/generic/guide_traces.jpg"
          alt="Characters"
        />
        <p>
          Traces are a skill upgrade tree that each Character has. Unlocking and
          upgrading the various nodes in a Character’s Trace will provide them
          with stat bonuses, extra passive abilities, and enhancements to their
          4 Skills.
        </p>
        <p>
          Traces can be upgraded by using materials found in the Crimson Calyx,
          as mission rewards, or purchased in the shop. Also, note that the
          ability to upgrade Traces is tied to your Character’s Ascension, so
          you will need to level your Character first before being able to
          upgrade their Traces.
        </p>
        <h5>Eidolons</h5>
        <StaticImage
          src="../../../images/starrail/generic/guide_eidolon.jpg"
          alt="Characters"
        />
        <p>
          Eidolons enhance Character’s abilities or provide them with new
          passive bonuses.
        </p>
        <p>
          Each Character has 6 Eidolon upgrades with each Eidolon upgrade
          providing a different bonus.
        </p>
        <p>
          Eidolon upgrades can be unlocked by obtaining duplicate copies of the
          Character from Warps (the gacha system) or through in-game missions
          and events. The only exception to this is the Main Character. You can
          obtain their Eidolon upgrades via in-game missions and Trailblaze
          Level (Account Level) rewards.
        </p>
        <h5>Light Cones</h5>
        <StaticImage
          src="../../../images/starrail/generic/guide_cone.jpg"
          alt="Characters"
        />
        <p>
          Light Cones are a type of equipment that all Characters can equip.
          They are the{' '}
          <strong>
            equivalent of weapons in other games and provide stat bonuses to the
            equipped Character
          </strong>
          .
        </p>
        <p>
          Similar to Characters, Light Cones also have a Level ranging from 1 to
          80. They can be leveled up and ascended as well. Doing so will
          increase the stat bonus that they provide to the equipped Character.
        </p>
        <p>
          Each Light Cone also has a{' '}
          <strong>
            Light Cone Ability which is a passive bonus that is tied to one of
            the seven Paths
          </strong>
          . A Light Cone Ability starts at Rank 1 and can be upgraded to Rank 5
          by using the Superimpose function.
        </p>
        <p>
          To use the Superimpose function, you will need to expend duplicate
          Light Cones of the one that you are upgrading. Each duplicate Light
          Cone that you use will increase the Light Cone Ability’s Rank by 1.
          Note that any Light Cones used as a Superimpose material will be
          consumed and destroyed. Increasing the Rank of a Light Cone Ability
          will enhance some or all of the numerical portion of the bonus that it
          provides.
        </p>
        <p>
          All Light Cones can be equipped to any Character, however{' '}
          <strong>
            only Characters of the Light Cone’s corresponding Path will be able
            to use its Light Cone Ability
          </strong>
          . This means that a Character that is equipped with a Light Cone of a
          different Path will only gain the stat bonuses that the Light Cone
          provides and not its Light Cone Ability.
        </p>
        <p>Check the list of available Light Cones here:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Light Cones"
            link="/star-rail/light-cones"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_lightcone.png"
                alt="Light Cones"
              />
            }
          />
        </Row>
        <h5>Relics</h5>
        <StaticImage
          src="../../../images/starrail/generic/guide_relics.jpg"
          alt="Characters"
        />
        <p>
          Relics are the other type of equipment in this game and serve the
          function of armor and accessories in a traditional RPG game. Equipping
          Relics to your Character will provide them with a variety of stat
          increases from HP to ATK to Speed. Relics will provide your Characters
          with the main bulk of their stat increases.
        </p>
        <p>There are 6 different Relic types:</p>
        <ul>
          <li>Head</li>
          <li>Hands</li>
          <li>Body</li>
          <li>Feet</li>
          <li>Planar Sphere</li>
          <li>Link Rope</li>
        </ul>
        <p>
          Note that the game refers to the Planar Sphere and Link Rope as
          Planetary Ornaments rather than Relics. This is only a terminology
          difference. For gameplay purposes, they are functionally the same.
        </p>
        <p>
          Each Relic has one main stat and up to 4 substats. Depending on the
          Relic type, the possible main stats are:
        </p>
        <ul>
          <li>
            <strong>Head</strong> - Flat HP
          </li>
          <li>
            <strong>Hands</strong> - Flat ATK
          </li>
          <li>
            <strong>Body</strong> - HP%, ATK%, DEF%, Crit Rate%, Crit DMG%,
            Outgoing Healing Boost%, Effect Hit Rate%
          </li>
          <li>
            <strong>Feet</strong> - HP%, ATK%, DEF%, Speed
          </li>
          <li>
            <strong>Planar Sphere</strong> - HP%, ATK%, DEF%, Elemental Damage
            Boost*
          </li>
          <li>
            <strong>Link Rope</strong> - HP%, ATK%, DEF%, Break Effect%, Energy
            Regen Rate%
          </li>
        </ul>
        <p>
          *Note that the Elemental Damage Boost will be one of the 7 possible
          elements. It is not an All Elemental Damage Boost.
        </p>
        <p>Learn more about Relics in our other guides:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Relics"
            link="/star-rail/guides/relics"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relicinfo.png"
                alt="Relics"
              />
            }
          />
          <CategoryCard
            title="Relic Sets"
            link="/star-rail/guides/relic-sets"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relics.png"
                alt="Relic Sets"
              />
            }
          />
          <CategoryCard
            title="Relic Stats"
            link="/star-rail/guides/relic-stats"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relicstats.png"
                alt="Relic Stats"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Exploration" />
        <p>
          Players are able to roam around and explore the various areas of the
          Honkai: Star Rail map for treasure chests, enemies, puzzles, and more.
          However, each area is its own separate zone, so there will be a
          loading screen when traveling between different areas in the
          overworld.
        </p>
        <p>
          When exploring the world, you will select one character out of the
          four in your team to be your active character. The active character is
          the one on screen that you control. You can switch the active
          character anytime when you are outside of combat.
        </p>
        <p>
          Outside of the town areas, there will be enemies roaming around the
          map that will aggro and chase you if you get too close to them.{' '}
        </p>
        <h5>Interactive Map</h5>
        <p>
          If you're struggling with finding all the Chests or just navigating
          some maps, you can use the official{' '}
          <a
            href="https://act.hoyolab.com/sr/app/interactive-map/"
            target="_blank"
            rel="noreferrer"
          >
            Interactive Map
          </a>{' '}
          created by the developers.
        </p>
        <h5>Initiating combat</h5>
        <p>
          To initiate combat in the overworld,{' '}
          <strong>approach an enemy and use your Basic Attack ability</strong>.{' '}
        </p>
        <p>
          Some{' '}
          <strong>
            Character’s Technique ability can also be used to initiate combat{' '}
          </strong>
          and when used to do so, will cause the Character to perform a special
          attack at the start of combat.{' '}
          <strong>
            Note that using a Character’s Technique ability to initiate combat
            will consume a Technique charge
          </strong>
          . You can store Technique charges initially. However, the maximum
          amount of Technique charges that you can store will increase as you
          level up your Trailblaze Level. Technique charges can be restored by
          breaking purple containers in the overworld. Breaking one of these
          containers will restore 2 Technique charges. These containers can
          generally be found near fast travel points (i.e. Space Anchors and
          Calyx entrances) that are outside of the city zones.
        </p>
        <p>
          Combat can also be initiated when an enemy that is chasing you manages
          to catch up with you. If you fail to hit them with either your Basic
          Attack or Technique ability before they make contact with you,{' '}
          <strong>then you will enter combat in the “Ambushed” state</strong>.
          In this state, the enemy will get to act before you do.
        </p>
        <SectionHeader title="Combat" />
        <p>
          Honkai: Star Rail utilizes a JRPG style turn-based combat system. You
          build a team of 4 Characters and take them into battle with you. In
          combat, Characters and enemies will act in a set turn order that is
          determined by their Speed stat. The Speed stat also determines how
          long it takes for them to act again.
        </p>
        <h5>Pre-Combat</h5>
        <p>
          While exploring in the overworld, attacking the roaming enemies or
          getting caught by them will initiate a combat sequence. Depending on
          the condition of how the battle was started, either your team or the
          enemy may suffer from a disadvantage.
        </p>
        <p>
          If you initiate the combat by attacking the enemy with either a Basic
          Attack or Technique, then the battle will commence normally. However,
          if the element of the active character that is initiating the combat
          is one that the enemy is weak against, then your team will start the
          battle with the Weakness state. When starting a battle in this state,
          the enemy’s Toughness bar will be reduced, making it easier to
          Weakness Break them. Essentially, this allows your team to have an
          easier time with dealing damage to the enemy.
        </p>
        <p>
          On the other hand, if an enemy manages to catch you and you fail to
          hit them with either your Basic Attack or Technique ability before
          they make contact, then you will enter combat in the Ambushed state.
          In this state, the enemy will get to act before you do.
        </p>
        <p>
          For more details on enemy elemental weakness and Weakness Break, see
          the “Enemies” section below.
        </p>
        <h5>Player Actions</h5>
        <p>
          During a Character’s turn, they are able to take one of the following
          actions:
        </p>
        <ul>
          <li>
            <strong>Use Basic Attack ability</strong>
          </li>
          <li>
            <strong>Use Skill ability</strong>
          </li>
          <ul>
            <li>Using a Skill ability consumes Skill Point(s).</li>
            <li>
              Skills Points can be generated primarily by using the Battle
              Attack ability.{' '}
            </li>
            <li>
              You can hold up to a maximum of 5 Skill Points and they are shared
              between the whole team.
            </li>
            <li>
              You cannot activate a Skill ability if you do not have any Skill
              Points.
            </li>
          </ul>
          <li>
            <strong>Use Ultimate ability if it is fully charged</strong>
          </li>
          <ul>
            <li>
              Using an Ultimate ability during the Character’s turn does not end
              that Character’s turn. The Character will use the Ultimate ability
              immediately, then you will be able to select another action for
              them to use.{' '}
            </li>
            <li>
              Ultimate abilities can also be used when it is not currently the
              Character’s turn. If used in such a way, then the Character will
              immediately activate their Ultimate ability after the current turn
              ends. This allows you to interrupt the normal turn order flow.
              Multiple Ultimates can also be chained in this way which can lead
              to very explosive out of turn damage.
            </li>
            <li>
              Ultimate abilities cost a certain amount of Energy to activate.
              Energy can be gained via the following actions:
            </li>
            <ul>
              <li>Using Basic Attack ability - Generates 20 Energy</li>
              <li>Using Skill ability - Generates 30 Energy</li>
              <li>Using Ultimate ability - Generates 5 Energy</li>
              <li>Defeating enemies Generates 10 Energy/enemy</li>
              <li>
                Getting hit by enemies in combat - Energy gained will vary
                depending on the enemy
              </li>
              <li>Characters and Light Cones effects</li>
            </ul>
            <li>
              The amount of Energy gained from each of the actions listed above
              can be increased by increasing the Character’s Energy Regeneration
              Rate stat.
            </li>
          </ul>
        </ul>
        <h5>Enemies</h5>
        <p>
          Enemies in Honkai: Star Rail have two different bars to represent
          their resilience: HP and Toughness.
        </p>
        <p>
          The <strong>HP bar is the red bar</strong> and represents how much
          damage they can take before falling in combat. The HP bar goes down
          whenever the unit takes damage.
        </p>
        <p>
          On the other hand, <strong>the Toughness bar (the white bar)</strong>{' '}
          can only be damaged by attacks of the elements that the enemy is weak
          to.
        </p>
        <p>
          Enemies have a set of elemental weaknesses (generally 2 or 3) which
          are displayed above their head.{' '}
          <strong>
            Attacking an enemy with an attack of an element that it is weak
            against will reduce its Toughness (the white bat above its HP)
          </strong>
          . You can inflict Weakness Break on an enemy by reducing its Toughness
          to 0. An enemy that has been Weakness Broken will suffer additional
          damage and a debuff based on the element of the attack that reduced
          its Toughness to 0.
        </p>
        <p>The Weakness Break for each element are as follows:</p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Element</th>
              <th>Damage</th>
              <th>Debuff</th>
              <th>Debuff info</th>
              <th>Is DoT?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="hsr-dmg phys">Physical</span>
              </td>
              <td>Highest</td>
              <td>Bleed</td>
              <td>
                Deals damage over time (based on enemy Max HP) lasting for 2
                turns.
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg fire">Fire</span>
              </td>
              <td>Highest</td>
              <td>Burn</td>
              <td>Deals damage over time lasting for 2 turns.</td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg wind">Wind</span>
              </td>
              <td>High</td>
              <td>Wind Shear</td>
              <td>
                Deals damage over time lasting for 2 turns (can stack up to 5
                times).
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg lightning">Lightning</span>
              </td>
              <td>Average</td>
              <td>Shock</td>
              <td>Deals damage over time lasting for 2 turns.</td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg ice">Ice</span>
              </td>
              <td>Average</td>
              <td>Freeze</td>
              <td>
                Deals damage and Freezes the enemy for 1 turn. After enemy is
                unfrozen, he skips a turn, but advances his action forward by
                50%.
              </td>
              <td>
                <FontAwesomeIcon icon={faXmark} className="red" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg quantum">Quantum</span>
              </td>
              <td>Low</td>
              <td>Entanglement</td>
              <td>
                Deals damage (based on enemy max Toughness) and Delays the enemy
                action based on Break Effect stat. Applies 1 stack whenever the
                enemy is hit (up to 5 stacks).
              </td>
              <td>
                <FontAwesomeIcon icon={faXmark} className="red" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg imaginary">Imaginary</span>
              </td>
              <td>None</td>
              <td>Imprisonment</td>
              <td>
                Delays the enemy action based on Break Effect stat and reduces
                their Speed by 10% for 1 turn.
              </td>
              <td>
                <FontAwesomeIcon icon={faXmark} className="red" width="22" />
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          While broken, the enemy’s Toughness remains at 0 and further attacks
          of an element that the enemy is weak against will have no additional
          effects on it. A broken enemy will recover from its broken state on
          its next turn. Its Toughness will also be replenished back to full and
          it can be Weakness Broken again.
        </p>
        <SectionHeader title="Additional tips" />
        <p>Check below videos if you want to learn more:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="FE01dQBWD5s" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="hkAZoAZ0tVQ" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Honkai: Star Rail | Prydwen Institute"
    description="All you need to know about Honkai: Star Rail."
  />
);
